import React from 'react'
import axios from 'axios';

class LoginForm extends React.Component {

  constructor(props) {
    super(props);
    const { csrfCookieName, csrfHeaderName } = this.props;
    axios.defaults.xsrfCookieName = csrfCookieName;
    axios.defaults.xsrfHeaderName = csrfHeaderName;
    axios.defaults.withCredentials = true;

    this.state = {
      user: {
        email: '',
        password: '',
        errors: {
          pristine: 'pristine'
        }
      },
      loginResult: {
        hasError: false,
        errorMessage: ''
      },
      loading: false
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  validateEmail(value) {
    const error = {};
    if (!value) {
      error['email'] = 'El correo electrónico es obligatorio.';
    }
    else {
      let emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (!value.match(emailFormat)) {
        error['email'] = 'El correo electrónico no es válido.';
      }
      else {
        if (value.length < 7) {
          error['email'] = 'El correo electrónico es muy corto.';
        }
        else {
          if (value.length > 200) {
            error['email'] = 'El correo electrónico es muy largo.';
          }
        }
      }
    }
    return error;
  }

  validatePassword(value) {
    const error = {};
    if (!value) {
      error['password'] = 'La contraseña es obligatoria.';
    }
    else {
      if (value.length < 3) {
        error['password'] = 'La contraseña es muy corta.';
      }
      else {
        if (value.length > 100) {
          error['password'] = 'La contraseña es muy larga.';
        }
      }
    }
    return error;
  }

  onChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    const { user } = this.state;

    user[name] = value;

    if ('pristine' in user.errors) {
      delete user.errors['pristine'];
    }

    if (name === 'email') {
      const emailError = this.validateEmail(value);
      if (Object.keys(emailError).length === 0) {
        if ('email' in user.errors) {
          delete user.errors['email'];
        }
      }
      else {
        user.errors = Object.assign(user.errors, emailError);
      }
    }

    if (name === 'password') {
      const passwordError = this.validatePassword(value);
      if (Object.keys(passwordError).length === 0) {
        if ('password' in user.errors) {
          delete user.errors['password'];
        }
      }
      else {
        user.errors = Object.assign(user.errors, passwordError);
      }
    }

    this.setState({
      user: user
    });
  }

  formIsValid() {
    const { user } = this.state;
    const emailError = this.validateEmail(user.email);
    const passwordError = this.validatePassword(user.password);
    return (
      Object.keys(emailError).length === 0 &&
      Object.keys(passwordError).length === 0
    );
  }

  onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    const self = this;
    const { apiBaseUrl, nextUrl } = this.props;
    const { user, loginResult } = this.state;
    
    loginResult.hasError = false;
    loginResult.errorMessage = '';

    this.setState({
      loading: true,
      loginResult: loginResult
    });

    const request = {
      method: 'post',
      url: apiBaseUrl + '/users/login/',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      data: user
    };

    axios(request)
      .then(response => {
        window.location.replace(nextUrl);
      })
      .catch(error => {
        loginResult.hasError = true;
        loginResult.errorMessage = error.response.data.error;
        self.setState({
          loading: false,
          loginResult: loginResult
        });
      });
  }

  render() {
    const { loading, user, loginResult } = this.state;
    const { authenticationModes, setAuthenticationMode } = this.props;

    return (
      <div className='container'>
        <h1 className="d-flex justify-content-between mt-3 mb-3">INICIO DE SESIÓN</h1>
        <form onSubmit={this.onSubmit} noValidate>
          <fieldset disabled={loading}>
            <div className="form-group mb-3 pb-0">
              <label>Correo electrónico</label>
              <input type="email" name="email"
                className={'form-control ' + ('email' in user.errors && 'is-invalid')}
                onChange={this.onChange}
                value={user.email}
                minLength={7}
                maxLength={200}
                placeholder=""
                required
                autoFocus />
            </div>
            <div className="form-group">
              <label>Contraseña</label>
              <input type="password" name="password"
                className={'form-control ' + ('password' in user.errors && 'is-invalid')}
                onChange={this.onChange}
                value={user.password}
                minLength={3}
                maxLength={100}
                placeholder=""
                required />
            </div>
            <div className="form-group mb-1">
              <button type="submit"
                className="btn btn-primary btn-block"
                disabled={!this.formIsValid()}>
                <strong>Ingresar {loading && <i className="fa fa-spinner fa-pulse fa-fw" />}</strong>
              </button>
              {loginResult.hasError &&
                <div className="alert alert-danger mt-3" role="alert">
                  {loginResult.errorMessage}
                </div>
              }
            </div>
            <div className="clearfix pt-1 pb-1">
              <hr />
            </div>
            <p className="text-center text-muted">
              <a href="#" className="login-signup-link"
                onClick={(e) => { setAuthenticationMode(e, authenticationModes.PASSWORD) }}>¿Te olvidaste la contraseña?</a>
            </p>
            <p className="text-center text-muted">
              ¿No tienes cuenta aún? &nbsp;
              <a href="#" className="login-signup-link"
                onClick={(e) => { setAuthenticationMode(e, authenticationModes.SIGNUP) }}>Registráte aquí</a>
            </p>
          </fieldset>
        </form>
      </div>
    );
  }
}

export default LoginForm;