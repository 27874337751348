export function updateQueryStringParameter(paramName, paramValue, pageTitle) {

    if (paramValue) {
        let url = window.location.href;
        let hash = location.hash;
        url = url.replace(hash, '');

        if (url.indexOf(paramName + "=") >= 0) {
            let prefix = url.substring(0, url.indexOf(paramName));
            let suffix = url.substring(url.indexOf(paramName));
            suffix = suffix.substring(suffix.indexOf("=") + 1);
            suffix = (suffix.indexOf("&") >= 0) ? suffix.substring(suffix.indexOf("&")) : "";
            url = prefix + paramName + "=" + paramValue + suffix;
        } else {
            if (url.indexOf("?") < 0)
                url += "?" + paramName + "=" + paramValue;
            else
                url += "&" + paramName + "=" + paramValue;
        }
        const newUrl = url + hash;
        window.history.pushState({}, pageTitle, newUrl);
    }
}

export function getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    let results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}