import React from 'react';
import SignupForm from '../SignupForm/SignupForm.jsx';
import LoginForm from '../LoginForm/LoginForm.jsx';
import PasswordForgotForm from '../PasswordForgotForm/PasswordForgotForm.jsx';
import { getUrlParameter, updateQueryStringParameter } from '../../utils/url.js';

const authenticationModes = {
    SIGNUP: 'SIGNUP',
    LOGIN: 'LOGIN',
    PASSWORD: 'PASSWORD'
}

class AuthenticationForm extends React.Component {

    constructor(props) {
        super(props);

        let selectedForm = authenticationModes.LOGIN;

        const pageParam = getUrlParameter('page');

        if (pageParam) {

            switch (pageParam.toLowerCase()) {

                case 'signup':
                    selectedForm = authenticationModes.SIGNUP;
                    break;

                case 'login':
                    selectedForm = authenticationModes.LOGIN;
                    break;

                case 'password':
                    selectedForm = authenticationModes.PASSWORD;
                    break;

                default:
                    selectedForm = authenticationModes.LOGIN;
            }
        }

        this.state = {
            authenticationMode: selectedForm
        };
        this.setAuthenticationMode = this.setAuthenticationMode.bind(this);
    }

    setAuthenticationMode(e, authenticationMode) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            authenticationMode: authenticationMode
        });

        var page = null;

        switch (authenticationMode) {

            case authenticationModes.LOGIN:
                page = 'login';
                break;

            case authenticationModes.SIGNUP:
                page = 'signup';
                break;

            case authenticationModes.PASSWORD:
                page = 'password';
                break;

            default:
                page = null;
        }

        if (page) {
            updateQueryStringParameter('page', page);
        }
    }

    render() {
        const { className, csrfCookieName, csrfHeaderName, apiBaseUrl, nextUrl } = this.props;
        const { authenticationMode } = this.state;
        let selectedForm;

        if (authenticationMode == authenticationModes.SIGNUP) {

            selectedForm = <SignupForm
                csrfCookieName={csrfCookieName}
                csrfHeaderName={csrfHeaderName}
                apiBaseUrl={apiBaseUrl}
                authenticationModes={authenticationModes}
                setAuthenticationMode={this.setAuthenticationMode}
                nextUrl={nextUrl} />;

        }

        if (authenticationMode == authenticationModes.LOGIN) {

            selectedForm = <LoginForm
                csrfCookieName={csrfCookieName}
                csrfHeaderName={csrfHeaderName}
                apiBaseUrl={apiBaseUrl}
                authenticationModes={authenticationModes}
                setAuthenticationMode={this.setAuthenticationMode}
                nextUrl={nextUrl} />;
        }

        if (authenticationMode == authenticationModes.PASSWORD) {

            selectedForm = <PasswordForgotForm
                csrfCookieName={csrfCookieName}
                csrfHeaderName={csrfHeaderName}
                apiBaseUrl={apiBaseUrl}
                authenticationModes={authenticationModes}
                setAuthenticationMode={this.setAuthenticationMode} />;
        }

        return (
            <div className={"container " + className}>
                {selectedForm}
            </div>
        );
    }
}

export default AuthenticationForm;