import React from 'react'
import axios from 'axios';

class SignupForm extends React.Component {

  constructor(props) {

    super(props);
    const { csrfCookieName, csrfHeaderName } = this.props;
    axios.defaults.xsrfCookieName = csrfCookieName;
    axios.defaults.xsrfHeaderName = csrfHeaderName;
    axios.defaults.withCredentials = true;

    this.state = {
      user: {
        firstName: '',
        lastName: '',
        email: '',
        emailConfirmation: '',
        mobilePhone: '',
        city: '',
        company: '',
        rolePosition: '',
        password1: '',
        password2: '',
        subscriptionType: '',
        errors: {
          pristine: 'pristine'
        }
      },
      signupResult: {
        hasError: false,
        errorMessage: ''
      },
      citiesByState: [],
      subscriptionTypes: [],
      loading: true
    };

    this.getInitialData = this.getInitialData.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.getInitialData();
  }

  getInitialData() {
    const self = this;
    const { apiBaseUrl } = this.props;

    axios.all([
      axios.get(apiBaseUrl + '/regions/PY/cities/'),
      axios.get(apiBaseUrl + '/subscriptions/subscription-types/'),
    ])
      .then(axios.spread((
        citiesByStateResponse,
        subscriptionTypesResponse
      ) => {
        const citiesByState = citiesByStateResponse.data.data;
        const subscriptionTypes = subscriptionTypesResponse.data.data;

        self.setState(prevState => ({
          citiesByState: citiesByState,
          subscriptionTypes: subscriptionTypes,
          loading: false
        }));
      }))
      .catch(error => {
        self.setState(prevState => ({
          loading: false
        }));
        console.error(error);
      });
  }

  validateFirstName(value) {
    const error = {};
    if (!value) {
      error['firstName'] = 'El nombre es obligatorio.';
    }
    else {
      if (value.length > 30) {
        error['firstName'] = 'El nombre es muy largo.';
      }
    }
    return error;
  }

  validateLastName(value) {
    const error = {};
    if (!value) {
      error['lastName'] = 'El apellido es obligatorio.';
    }
    else {
      if (value.length > 30) {
        error['lastName'] = 'El apellido es muy largo.';
      }
    }
    return error;
  }

  validateEmail(value) {
    const error = {};
    if (!value) {
      error['email'] = 'El correo electrónico es obligatorio.';
    }
    else {
      let emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (!value.match(emailFormat)) {
        error['email'] = 'El correo electrónico no es válido.';
      }
      else {
        if (value.length < 7) {
          error['email'] = 'El correo electrónico es muy corto.';
        }
        else {
          if (value.length > 200) {
            error['email'] = 'El correo electrónico es muy largo.';
          }
        }
      }
    }
    return error;
  }

  validateEmailConfirmation(email, emailConfirmation) {
    const error = {};
    if (!emailConfirmation) {
      error['emailConfirmation'] = 'La confirmación del correo electrónico es obligatoria.';
    }
    else {
      let emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (!emailConfirmation.match(emailFormat)) {
        error['emailConfirmation'] = 'La confirmación del correo electrónico no es válida.';
      }
      else {
        if (emailConfirmation.length < 7) {
          error['emailConfirmation'] = 'El correo electrónico es muy corto.';
        }
        else {
          if (emailConfirmation.length > 200) {
            error['emailConfirmation'] = 'El correo electrónico es muy largo.';
          }
          else {
            if (email !== emailConfirmation) {
              error['emailConfirmation'] = 'Las direcciones de correo electrónico no coinciden.';
            }
          }
        }
      }
    }
    return error;
  }

  validateMobilePhone(value) {
    const error = {};
    if (!value) {
      error['mobilePhone'] = 'El nro. de teléfono móvil es obligatorio.';
    }
    else {
      if (value.length > 13) {
        error['mobilePhone'] = 'El nro. de teléfono móvil es muy largo.';
      }
    }
    return error;
  }

  validateCity(value) {
    const error = {};
    if (!value) {
      error['city'] = 'La ciudad es obligatoria.';
    }
    return error;
  }

  validateCompany(value) {
    const error = {};
    if (!value) {
      error['company'] = 'La empresa es obligatoria.';
    }
    else {
      if (value.length > 30) {
        error['company'] = 'La empresa es muy larga.';
      }
    }
    return error;
  }

  validateRolePosition(value) {
    const error = {};
    if (!value) {
      error['rolePosition'] = 'El rol o cargo es obligatorio.';
    }
    else {
      if (value.length > 30) {
        error['rolePosition'] = 'El rol o cargo es muy largo.';
      }
    }
    return error;
  }

  validatePassword1(value) {
    const error = {};
    if (!value) {
      error['password1'] = 'La contraseña es obligatoria.';
    }
    else {
      if (value.length < 8) {
        error['password1'] = 'La contraseña es muy corta.';
      }
      else {
        if (value.length > 100) {
          error['password1'] = 'La contraseña es muy larga.';
        }
      }
    }
    return error;
  }

  validatePassword2(password1, password2) {
    const error = {};
    if (!password2) {
      error['password2'] = 'La contraseña es obligatoria.';
    }
    else {
      if (password2.length < 8) {
        error['password2'] = 'La contraseña es muy corta.';
      }
      else {
        if (password2.length > 100) {
          error['password2'] = 'La contraseña es muy larga.';
        }
        else {
          if (password1 !== password2) {
            error['password2'] = 'Las contraseñas no coinciden.';
          }
        }
      }
    }
    return error;
  }

  validateSubscriptionType(value) {
    const error = {};
    if (!value) {
      error['subscriptionType'] = 'La suscripción es obligatoria.';
    }
    return error;
  }

  formIsValid() {
    const { user } = this.state;
    const firstNameError = this.validateFirstName(user.firstName);
    const lastNameError = this.validateLastName(user.lastName);
    const emailError = this.validateEmail(user.email);
    const emailConfirmationError = this.validateEmailConfirmation(user.email, user.emailConfirmation);
    const mobilePhoneError = this.validateMobilePhone(user.mobilePhone);
    const cityError = this.validateCity(user.city);
    const companyError = this.validateCompany(user.company);
    const rolePositionError = this.validateRolePosition(user.rolePosition);
    const password1Error = this.validatePassword1(user.password1);
    const password2Error = this.validatePassword2(user.password1, user.password2);
    const subscriptionTypeError = this.validateSubscriptionType(user.subscriptionType);

    return (
      Object.keys(firstNameError).length === 0 &&
      Object.keys(lastNameError).length === 0 &&
      Object.keys(emailError).length === 0 &&
      Object.keys(emailConfirmationError).length === 0 &&
      Object.keys(mobilePhoneError).length === 0 &&
      Object.keys(cityError).length === 0 &&
      Object.keys(companyError).length === 0 &&
      Object.keys(rolePositionError).length === 0 &&
      Object.keys(password1Error).length === 0 &&
      Object.keys(password2Error).length === 0 &&
      Object.keys(subscriptionTypeError).length === 0
    );
  }

  onChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    const { user } = this.state;

    user[name] = value;

    if ('pristine' in user.errors) {
      delete user.errors['pristine'];
    }

    if (name === 'firstName') {

      const firstNameError = this.validateFirstName(value);

      if (Object.keys(firstNameError).length === 0) {

        if ('firstName' in user.errors) {
          delete user.errors['firstName'];
        }
      }
      else {
        user.errors = Object.assign(user.errors, firstNameError);
      }
    }

    if (name === 'lastName') {

      const lastNameError = this.validateLastName(value);

      if (Object.keys(lastNameError).length === 0) {

        if ('lastName' in user.errors) {
          delete user.errors['lastName'];
        }
      }
      else {
        user.errors = Object.assign(user.errors, lastNameError);
      }
    }

    if (name === 'email') {

      const emailError = this.validateEmail(value);

      if (Object.keys(emailError).length === 0) {

        if ('email' in user.errors) {
          delete user.errors['email'];
        }
      }
      else {
        user.errors = Object.assign(user.errors, emailError);
      }
    }

    if (name === 'emailConfirmation') {

      const emailConfirmationError = this.validateEmailConfirmation(user['email'], value);

      if (Object.keys(emailConfirmationError).length === 0) {

        if ('emailConfirmation' in user.errors) {
          delete user.errors['emailConfirmation'];
        }
      }
      else {
        user.errors = Object.assign(user.errors, emailConfirmationError);
      }
    }

    if (name === 'mobilePhone') {

      const mobilePhoneError = this.validateMobilePhone(value);

      if (Object.keys(mobilePhoneError).length === 0) {

        if ('mobilePhone' in user.errors) {
          delete user.errors['mobilePhone'];
        }
      }
      else {
        user.errors = Object.assign(user.errors, mobilePhoneError);
      }
    }

    if (name === 'city') {

      const cityError = this.validateCity(value);

      if (Object.keys(cityError).length === 0) {

        if ('city' in user.errors) {
          delete user.errors['city'];
        }
      }
      else {
        user.errors = Object.assign(user.errors, cityError);
      }
    }

    if (name === 'company') {

      const companyError = this.validateCompany(value);

      if (Object.keys(companyError).length === 0) {

        if ('company' in user.errors) {
          delete user.errors['company'];
        }
      }
      else {
        user.errors = Object.assign(user.errors, companyError);
      }
    }

    if (name === 'rolePosition') {

      const rolePositionError = this.validateCompany(value);

      if (Object.keys(rolePositionError).length === 0) {

        if ('rolePosition' in user.errors) {
          delete user.errors['rolePosition'];
        }
      }
      else {
        user.errors = Object.assign(user.errors, rolePositionError);
      }
    }

    if (name === 'password1') {

      const password1Error = this.validatePassword1(value);

      if (Object.keys(password1Error).length === 0) {

        if ('password1' in user.errors) {
          delete user.errors['password1'];
        }
      }
      else {
        user.errors = Object.assign(user.errors, password1Error);
      }
    }

    if (name === 'password2') {

      const password2Error = this.validatePassword2(user['password1'], value);

      if (Object.keys(password2Error).length === 0) {

        if ('password2' in user.errors) {
          delete user.errors['password2'];
        }
      }
      else {
        user.errors = Object.assign(user.errors, password2Error);
      }
    }

    if (name === 'subscriptionType') {

      const subscriptionTypeError = this.validateSubscriptionType(value);

      if (Object.keys(subscriptionTypeError).length === 0) {

        if ('subscriptionType' in user.errors) {
          delete user.errors['subscriptionType'];
        }
      }
      else {
        user.errors = Object.assign(user.errors, subscriptionTypeError);
      }
    }

    this.setState({
      user: user
    });
  }

  onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    const { apiBaseUrl, nextUrl } = this.props;
    const { user, signupResult } = this.state;
    const self = this;

    signupResult.hasError = false;
    signupResult.errorMessage = '';

    this.setState({
      loading: true,
      signupResult: signupResult
    });

    const request = {
      method: 'post',
      url: apiBaseUrl + '/users/signup/',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      data: user
    };

    axios(request)
      .then(response => {
        window.location.replace(nextUrl);
      })
      .catch(error => {
        signupResult.hasError = true;
        signupResult.errorMessage = error.response.data.error;
        self.setState({
          loading: false,
          signupResult: signupResult
        });
      });
  }

  render() {
    const { loading, user, citiesByState, subscriptionTypes, signupResult } = this.state;
    const { authenticationModes, setAuthenticationMode } = this.props;

    const citiesByStateOptions = citiesByState.map(function (state) {
      return (
        <optgroup key={state.id} label={state.name}>
          {state.cities.map((city) => {
            return (
              <option key={city.id} value={city.id}>
                {city.name}
              </option>
            );
          })}
        </optgroup>
      );
    });

    const subscriptionTypeOptions = subscriptionTypes.map(function (item) {
      return (
        <option key={item.id} value={item.id}>
          {item.name} - {item.priceFormatted}
        </option>
      );
    });

    return (
      <div className='container'>
        <h1 className="d-flex justify-content-between mt-3 mb-3">REGISTRO DE USUARIO</h1>
        <form onSubmit={this.onSubmit} noValidate>
          <fieldset disabled={loading}>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Nombre</label>
                <input type="text" name="firstName"
                  className={'form-control ' + ('firstName' in user.errors && 'is-invalid')}
                  onChange={this.onChange}
                  value={user.firstName}
                  minLength={1}
                  maxLength={30}
                  placeholder=""
                  autoComplete="new-password"
                  required />
              </div>
              <div className="form-group col-md-6">
                <label>Apellido</label>
                <input type="text" name="lastName"
                  className={'form-control ' + ('lastName' in user.errors && 'is-invalid')}
                  onChange={this.onChange}
                  value={user.lastName}
                  minLength={1}
                  maxLength={30}
                  placeholder=""
                  autoComplete="new-password"
                  required />
              </div>
              <div className="form-group col-md-6">
                <label>Correo electrónico</label>
                <input type="email" name="email"
                  className={'form-control ' + ('email' in user.errors && 'is-invalid')}
                  onChange={this.onChange}
                  value={user.email}
                  minLength={7}
                  maxLength={200}
                  placeholder=""
                  autoComplete="new-password"
                  required />
              </div>
              <div className="form-group col-md-6">
                <label>Confirme su correo electrónico</label>
                <input type="email" name="emailConfirmation"
                  className={'form-control ' + ('emailConfirmation' in user.errors && 'is-invalid')}
                  onChange={this.onChange}
                  value={user.emailConfirmation}
                  minLength={7}
                  maxLength={200}
                  placeholder=""
                  autoComplete="new-password"
                  required />
              </div>
              <div className="form-group col-md-6">
                <label>Contraseña</label>
                <input type="password" name="password1"
                  className={'form-control ' + ('password1' in user.errors && 'is-invalid')}
                  onChange={this.onChange}
                  value={user.password1}
                  minLength={8}
                  maxLength={100}
                  placeholder=""
                  autoComplete="new-password"
                  required />
                <small className="form-text text-muted">* La contraseña debe tener como mínimo 8 caracteres.</small>
              </div>
              <div className="form-group col-md-6">
                <label>Confirme su contraseña</label>
                <input type="password" name="password2"
                  className={'form-control ' + ('password2' in user.errors && 'is-invalid')}
                  onChange={this.onChange}
                  value={user.password2}
                  minLength={8}
                  maxLength={100}
                  placeholder=""
                  autoComplete="new-password"
                  required />
              </div>
              <div className="form-group col-md-6">
                <label>Teléfono móvil</label>
                <input type="text" name="mobilePhone"
                  className={'form-control ' + ('mobilePhone' in user.errors && 'is-invalid')}
                  onChange={this.onChange}
                  value={user.mobilePhone}
                  minLength={10}
                  maxLength={13}
                  placeholder=""
                  autoComplete="new-password"
                  required />
              </div>
              <div className="form-group col-md-6">
                <label>Ciudad</label>
                <select name="city"
                  className={'form-control custom-select ' + ('city' in user.errors && 'is-invalid')}
                  onChange={this.onChange}
                  value={user.city}
                  required>
                  <option value="" disabled selected></option>
                  {citiesByStateOptions}
                </select>
              </div>
              <div className="form-group col-md-6">
                <label>Empresa</label>
                <input type="text" name="company"
                  className={'form-control ' + ('company' in user.errors && 'is-invalid')}
                  onChange={this.onChange}
                  value={user.company}
                  minLength={3}
                  maxLength={30}
                  placeholder=""
                  autoComplete="new-password"
                  required />
              </div>
              <div className="form-group col-md-6">
                <label>Rol / Posición</label>
                <input type="text" name="rolePosition"
                  className={'form-control ' + ('rolePosition' in user.errors && 'is-invalid')}
                  onChange={this.onChange}
                  value={user.rolePosition}
                  minLength={3}
                  maxLength={30}
                  placeholder=""
                  autoComplete="new-password"
                  required />
              </div>
              <div className="form-group col-md-6">
                <label>Seleccione el plan de su preferencia</label>
                <select name="subscriptionType"
                  className={'form-control custom-select ' + ('subscriptionType' in user.errors && 'is-invalid')}
                  onChange={this.onChange}
                  value={user.subscriptionType}
                  required>
                  <option value="" disabled selected></option>
                  {subscriptionTypeOptions}
                </select>
              </div>
              <div className="form-group col-md-6"></div>
            </div>
            <div className="form-group mb-1">
              <button type="submit"
                className="btn btn-primary btn-block"
                disabled={!this.formIsValid()}>
                <strong>Registrarme {loading && <i className="fa fa-spinner fa-pulse fa-fw" />}</strong>
              </button>
              {signupResult.hasError &&
                <div className="alert alert-danger mt-3" role="alert">
                  {signupResult.errorMessage}
                </div>
              }
            </div>
            <div className="clearfix pt-1 pb-1">
              <hr />
            </div>
            <p className="text-center text-muted">
              ¿Ya tienes una cuenta? &nbsp;
              <a href="#" className="login-signup-link"
                onClick={(e) => { setAuthenticationMode(e, authenticationModes.LOGIN) }}>Ingresá aquí</a>
            </p>
          </fieldset>
        </form>
      </div>
    );
  }
}

export default SignupForm;